:root{
  --primary-color:#49515a;
  --primary-light-color:#49515a89;
  --secondry-color:#ff963b;
  --secondary-color-rgb: 255, 150, 59;
  --white-color:#fff;
  --light-white:#999;
  --light-green:#66cb8c;
  --font-montserrat:"Montserrat", sans-serif;
}
body{
  background: #DAE3E7 !important;
  font-family: var(--font-montserrat);
}
.lightw-color{
  color: var(--light-white);
}
.orange-color{
  color: var(--secondry-color);
}
.cs_details{
  color: var(--primary-color);
  font-family: var(--font-montserrat);
}
.bg-w{
 background-color: var(--white-color);
}
.cs_profile{
  gap: 26px;
  color:var(--primary-color)
}
body .btn-style{
  background-color:transparent;
  border:0;
  color: var(--primary-color);
  position: relative;
  z-index: 2;
  &:hover,
  &:active{
    background-color: transparent !important;
    color: var(--white-color);
  }
}
a.cs_btn{
  position: relative;
  padding: 18px 20px;
  transition: all 0.2s ease;
  svg{
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
    fill: var(--primary-color);
    font-size: 28px;
  }
  &:hover{
    svg{
      transform: translateX(0);
      fill: var(--white-color);
    }
    &:after{
      width: 100%;
      background: var(--secondry-color);
    }
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background: rgba(var(--secondary-color-rgb), 0.5);;
    width: 56px;
    height: 56px;
    z-index: 1;
    transition: all 0.3s ease;
  }
}
.profile_img {
  width: 180px;
}
.social-media{
  a{
    margin-right: 10px;
    &:hover{
      path{
        fill: var(--secondry-color);
      }
    }
  }
  svg{
    font-size: 24px;
  }
  path{
    fill: var(--primary-light-color);
  }
} 
.cs_address{
  .list-group-item{
    color: var(--primary-color);
    svg{
      margin-right:12px;
    }
  }
  a{
    color: var(--primary-color);
    text-decoration: none;
    &:hover{
      color: var(--secondry-color);
      text-decoration: underline;
    }
  }
}
.portfolio_list{
  .cs_details{
    display: flex;
    gap: 16px;
    &:first-child{
      display: block;
      border-bottom: 1px solid #cccccc38;
      margin-bottom: 60px;
      .portfolio_img {
        max-width: 100%;
      }
      .portfolio_Details{
        .title{
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }
  }
  .portfolio_img {
    max-width: 220px;
    border: 1px solid #cccccc38;
    display: flex;
    align-items: center;
  }
  .portfolio_Details{
    button{
      font-size: 16px;
    }
    p{
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
    a.cs_btn {
      padding: 12px 0px;
      &:hover{
        &:after {
          width: 100%;
        }
      }
      &:after{
        width: 40px;
        height: 40px;
      }
    }
    
  }
}
.progress_bar{
  .cs_expert{
    float: right;
    font-size: 12px;
    color: var(--light-white);
  }
}
.cs_course{
  .course{
    font-size: 18px;
    margin-bottom: 0px;
    padding-left: 34px;
    svg{
      font-size: 20px;
      margin-right: 10px;
      margin-left: -35px;
    }
  }
  .university{
    font-size: 14px;
    padding-left: 34px;
    color: #00000094;
    .cs_year{
      color: var(--light-white);
    }
  }

}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
  position: fixed;
  max-width: 440px;
}
a.cs_btn{
  &.active{
    path{
      fill:var(--white-color);
    }
    button{
      color: var(--white-color);
    }
    &:after{
      width: 100%;
      background-color: var(--secondry-color);
    }
  }
}

.modal-content{
  .btn-close{
    position: absolute;
    right: -10px;
    top:-10px;
    opacity: 1;
    font-size: 0px;
    width: auto;
    &:focus{
      outline: none;
      box-shadow: none;
    }
    &:before {
      background-color:var(--secondry-color);
      border-radius: 50px;
      content: "x";
      color: #fff !important;
      font-size: 24px;
      width: 24px;
      height: 24px;
      /* padding: 19px; */
      display: block;
      line-height: 24px;
    }
  }
  .modal-body{
    .form-control{
      &:focus{
        box-shadow:0 0 0 .25rem rgba(var(--secondary-color-rgb), 0.5); 
        border-color: var(--secondry-color);
      }
    }
  }
}
@media screen and (max-width:767px){
  .portfolio_list{
    .cs_details{
      display: block;
    }
    .portfolio_img {
      max-width: 100%;
    }
  }
}
@media screen and (max-width:575px){
  .cs_profile{
    text-align: center;
    .profile_img{
      margin: 0 auto;
    }
  }
}